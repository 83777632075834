export default {
  en: {
    settings: "Settings",
    organization: "Organization",
    team: "Team",
    personal_information: "Personal Information",
    organization_settings: "Organization Settings",
    add_new_organization: "Add New Organization",
    fill_in_the_organization_details: "Fill in the details of the ticket sales organization",
    organization_name: "Organization Name",
    organization_name_required: "Organization name is required.",
    organization_url: "Organization URL",
    url_info: "All your activities will appear at this URL",
    checking_url: "Checking URL...",
    url_available: "This URL is still available",
    url_in_use: "This URL is already in use",
    website_title_required: "Website title is required.",
    create_organization: "Create Organization",
    manage_timeslots: "Manage the timeslots for the activity",
    theme: "Theme",
    theme_name: "Theme name",
    theme_color: "Theme color",
    background_color: "Background color",
    font: "Font",
    logo: "Logo",
    add_theme: "Add Theme",
    default_font: "Default font",
    font_input_default: "Font Input Default",
    sent: "Sent",
    visitor_information: "Visitor Information",
    documents: "Documents",
    view_dashboard: "View Dashboard",
  },
  nl: {
    settings: "Instellingen",
    organization: "Organisatie",
    team: "Team",
    personal_information: "Persoonlijke informatie",
    organization_settings: "Organisatie Instellingen",
    add_new_organization: "Nieuwe Organisatie Toevoegen",
    fill_in_the_organization_details: "Vul de gegevens van de ticketverkooporganisatie in",
    organization_name: "Organisatienaam",
    organization_name_required: "Organisatienaam is verplicht.",
    organization_url: "Organisatie URL",
    url_info: "Al je activiteiten verschijnen op deze URL",
    checking_url: "URL aan het controleren...",
    url_available: "Deze URL is nog beschikbaar",
    url_in_use: "Deze URL is al in gebruik",
    website_title_required: "Website titel is verplicht.",
    create_organization: "Organisatie Aanmaken",
    manage_timeslots: "Beheer de tijdsloten voor de activiteit",
    theme: "Thema",
    theme_name: "Thema naam",
    theme_color: "Thema kleur",
    background_color: "Achtergrond kleur",
    font: "Lettertype",
    logo: "Logo",
    add_theme: "Thema toevoegen",
    default_font: "Default font",
    font_input_default: "Font Input Default",
  },
};
