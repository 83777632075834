export const tickets = {
  tickets: "Tickets",
  ticket_design: "Ticket Design",
  set_ticket_design: "Set the ticket design for PDF files.",
  default_ticket_design: "Default ticket design",
  ticket_types: "Ticket Types",
  new_ticket_type: "Add Ticket Type",
  edit_ticket_type: "Edit Ticket Type",
  add_ticket_type: "Add Ticket Type",
  ticket_type: "Ticket Type",
  ticket_type_placeholder: "Ticket Type",
  available_tickets: "Available tickets",
  available_ticket_types: "Available ticket types",
  minimum_tickets: "Minimum number of tickets",
  order_limit: "Limit per order",
  reserved: "Reserved",
  tickets_from: 'Tickets are available from',
  no_tickets_available_anymore: 'No tickets available anymore',
  maximum: 'Maximum',
  all_tickets: "All tickets",
  ticket: "Ticket",
};
export default {
  en: {
    tickets: "Tickets",
    ticket_type: "Ticket Type",
    ticket_types: "Ticket Types",
    new_ticket_type: "Add Ticket Type",
    edit_ticket_type: "Edit Ticket Type",
    add_ticket_type: "Add Ticket Type",
    ticket_design: "Ticket Design",
    set_ticket_design: "Set the ticket design for PDF files.",
    default_ticket_design: "Default ticket design",
    available_tickets: "Available tickets",
    available_ticket_types: "Available ticket types",
    ticket_name: "Ticket name",
    available_tickets_per_timeslot: "Available tickets (Per time slot)",
    unlimited_until_capacity_is_reached: "Unlimited until capacity is reached",
    minimum_per_order: "Minimum per order",
    limit_per_order: "Limit per order",
    tickets_issued: "Tickets Issued",
    issued: "Issued",
    tickets_checked_in: "Tickets Checked In",
    tickets_temporarily_reserved: "The tickets are temporarily reserved and not yet definitively sold, the visitor must complete the payment.",
    provisionally_reserved: "Provisionally Reserved",
    check_in_tickets: "Check In Tickets",
    check_in_x_tickets: "Check In {count} Tickets",
    checked_in_on: "Checked in on",
    check_in_all_tickets: "Check In All Tickets",
    all_tickets: "All tickets",
    ticket: "Ticket",
    scan_qr_code: "Scan the QR code with your phone to start scanning tickets immediately",
    add_scanner: "Add Scanner",
    scan_profile: "Scan Profile",
    last_connected: "Last Connected",
    new_scan_profile: "New Scan Profile",
    edit_scan_profile: "Edit Scan Profile",
    scan_profiles: "Scan Profiles",
    add_scan_profile: "Add Scan Profile",
    number_of_allowed_timeslots: "Number of Allowed Timeslots",
    number_of_allowed_ticket_types: "Number of Allowed Ticket Types",
    scanner_permission: "What can scanners scan with this profile?",
    sent: "Sent",
    visitor_information: "Visitor Information",
    documents: "Documents",
    view_dashboard: "View Dashboard",
  },
  nl: {
    tickets: "Tickets",
    new_ticket_type: "Nieuwe ticketsoort",
    edit_ticket_type: "Ticketsoort bewerken",
    add_ticket_type: "Ticketsoort toevoegen",
    ticket_design: "Ticket ontwerp",
    set_ticket_design: "Stel het ticket ontwerp in voor PDF bestanden.",
    default_ticket_design: "Standaard ticket ontwerp",
    available_tickets: "Beschikbare tickets",
    available_ticket_types: "Beschikbare ticketsoorten",
    ticket_name: "Ticket naam",
    available_tickets_per_timeslot: "Beschikbare tickets (Per tijdslot)",
    unlimited_until_capacity_is_reached: "Onbeperkt tot capaciteit is bereikt",
    minimum_per_order: "Minimaal per bestelling",
    limit_per_order: "Limiet per bestelling",
    tickets_issued: "Tickets Uitgegeven",
    issued: "Uitgegeven",
    tickets_checked_in: "Tickets Ingecheckt",
    tickets_temporarily_reserved: "De tickets zijn tijdelijk gereserveerd en nog niet definitief verkocht, de bezoeker moet de betaling nog afronden.",
    provisionally_reserved: "Voorlopig Gereserveerd",
    check_in_tickets: "Tickets inchecken",
    check_in_x_tickets: "Check {count} tickets in",
    checked_in_on: "Ingecheckt op",
    check_in_all_tickets: "Alle tickets inchecken",
    all_tickets: "Alle tickets",
    ticket: "Ticket",
    scan_qr_code: "Scan de QR-code met je telefoon om direct te starten met tickets scannen",
    add_scanner: "Scanner toevoegen",
    scan_profile: "Scan profiel",
    last_connected: "Laatst verbonden",
    new_scan_profile: "Nieuw scan profiel",
    edit_scan_profile: "Scan profiel bewerken",
    scan_profiles: "Scan profielen",
    add_scan_profile: "Scan profiel toevoegen",
    number_of_allowed_timeslots: "Aantal toegestaane tijdsloten",
    number_of_allowed_ticket_types: "Aantal toegestaane ticketsoorten",
    scanner_permission: "Wat kunnen scanners scannen met dit profiel?",
    seats: 'Plaatsen'
  },
};
