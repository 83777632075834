export default {
  en: {
    information: "Information",
    search: "Search",
    date: "Date",
    title: "Title",
    description: "Description",
    website: "Website",
    save: "Save",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    actions: "Actions",
    delete: "Delete",
    edit: "Edit",
    view: "Theme",
    back: "Back",
    continue: "Continue",
    close: "Close",
    optional: "Optional",
    upload: "Upload",
    remove: "Remove",
    add: "Add",
    select: "Select",
    confirm: "Confirm",
    invalid: "Invalid",
    valid: "Valid",
    required: "Required",
    more_info: "More Information",
    settings: "Settings",
    manage: "Manage",
    status: "Status",
    total: "Total",
    amount: "Amount",
    price: "Price",
    name: "Name",
    email: "Email",
    address: "Address",
    location: "Location",
    start: "Start",
    start_time: "Start Time",
    end: "End",
    end_time: "End Time",
    minutes: "Minutes",
    maximum: "Maximum",
    unlimited: "Unlimited",
    visibility: "Visibility",
    visible: "Visible",
    hidden: "Hidden",
    reserved: "Reserved",
    connected: "Connected",
    reconnect: "Reconnect",
    search_placeholder: "Search",
    no_data_found: "No data found",
    refresh: "Refresh",
    export_all: "Export All",
    options: "Options",
    select_all: "Select All",
    clear_selection: "Clear Selection",
    or: "or",
    share_url: "Share this URL",
    all: "All",
    item: "Item",
    type_field: "Type Field",
    frequency: "Frequency",
    field_not_ask_event: "Do not ask field for this event",
    choice_option: "Choice Option",
    add_choice_option: "Add Choice Option",
    add_field: "Add Field",
    create_new_field: "Create New Field",
    add_selected_fields: "Add Selected Fields",
    dashboard: "Dashboard",
    activities: "Activities",
    orders: "Orders",
    scan: "Scan",
    payout: "Payout",
    select_visit_type: "Select Visit Type",
    visitor: "Visitor",
    timeslot_placeholder: "Timeslot",
    ticket_type_placeholder: "Ticket type",
    status_placeholder: "Status",
    event_management: "Manage your event and location information",
    your_ticketshop_url: "Your Ticketshop URL",
    share_your_ticketshop: "Share Your Ticketshop",
    start_ticket_sales_from: "Start ticket sales from",
    extra_information: "Extra Information",
    more_info_ticketshop: "More information about your ticketshop",
    extra_info_confirmation_email: "Extra information in confirmation email",
    sent: "Sent",
    documents: "Documents",
    view_dashboard: "View Dashboard",
    manage_visitor_questions: "Manage Visitor Questions",
    per_order: "Per Order",
    per_ticket: "Per Ticket",
    manage_theme: "Manage Theme",
    ticketshop_online: "Put Ticketshop Online",
    language: "Language",
    paid: "Paid"
  },
  nl: {
    information: "Informatie",
    dashboard: "Dashboard",
    activities: "Activiteiten",
    orders: "Bestellingen",
    scan: "Scannen",
    payout: "Uitbetaling",
    select_visit_type: "Bezoektype",
    visitor: "Bezoeker",
    timeslot_placeholder: "Tijdslot",
    ticket_type_placeholder: "Ticketsoort",
    status_placeholder: "Status",
    event_management: "Beheer je evenement en locatie informatie",
    your_ticketshop_url: "Jouw Ticketshop URL",
    share_your_ticketshop: "Deel jouw Ticketshop",
    start_ticket_sales_from: "Start ticketverkoop vanaf",
    extra_information: "Extra Informatie",
    more_info_ticketshop: "Meer informatie over jouw ticketshop",
    extra_info_confirmation_email: "Extra informatie in bevestigingsmail",
    manage_visitor_questions: "Beheer Bezoekersvragen",
    per_order: "Per Bestelling",
    per_ticket: "Per Ticket",
    manage_theme: "Thema Beheren",
    ticketshop_online: "Zet ticketshop online",
    language: "Taal",
    search: "Zoeken",
    date: "Datum",
    title: "Titel",
    description: "Omschrijving",
    website: "Website",
    save: "Opslaan",
    cancel: "Annuleren",
    yes: "Ja",
    no: "Nee",
    actions: "Acties",
    delete: "Verwijderen",
    edit: "Bewerken",
    view: "Thema",
    back: "Terug",
    continue: "Doorgaan",
    close: "Sluiten",
    optional: "Optioneel",
    upload: "Uploaden",
    remove: "Verwijderen",
    add: "Toevoegen",
    select: "Selecteren",
    confirm: "Bevestigen",
    invalid: "Ongeldig",
    valid: "Geldig",
    required: "Verplicht",
    more_info: "Meer informatie",
    settings: "Instellingen",
    manage: "Beheren",
    status: "Status",
    total: "Totaal",
    amount: "Bedrag",
    price: "Prijs",
    name: "Naam",
    email: "E-mail",
    address: "Adres",
    location: "Locatie",
    start: "Start",
    start_time: "Starttijd",
    end: "Einde",
    end_time: "Eindtijd",
    minutes: "Minuten",
    maximum: "Maximaal",
    unlimited: "Onbeperkt",
    visibility: "Zichtbaarheid",
    visible: "Zichtbaar",
    hidden: "Verborgen",
    reserved: "Gereserveerd",
    connected: "Verbonden",
    reconnect: "Opnieuw verbinden",
    search_placeholder: "Zoeken",
    no_data_found: "Geen gegevens gevonden",
    refresh: "Verversen",
    export_all: "Alles exporteren",
    options: "Opties",
    select_all: "Alles selecteren",
    clear_selection: "Selectie wissen",
    or: "of",
    share_url: "Deel deze URL",
    all: "Alles",
    item: "Item",
    type_field: "Type veld",
    frequency: "Frequentie",
    field_not_ask_event: "Veld niet vragen voor dit evenement",
    choice_option: "Keuzeoptie",
    add_choice_option: "Keuzeoptie toevoegen",
    add_field: "Veld toevoegen",
    create_new_field: "Nieuw veld maken",
    add_selected_fields: "Geselecteerde velden toevoegen",
    paid: "Betaald"
  },
};
