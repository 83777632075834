export default {
  en: {
    themes: "Themes",
    add_theme: "Add Theme",
    edit_theme: "Edit Theme",
    manage_theme: "Manage how the ticket shop looks for visitors",
    sent: "Sent",
    visitor_information: "Visitor Information",
    documents: "Documents",
    view_dashboard: "View Dashboard",

  },
  nl: {
    themes: "Thema's",
    add_theme: "Thema Toevoegen",
    edit_theme: "Thema Bewerken",
    manage_theme: "Beheer hoe de ticketshop eruit ziet voor bezoekers.",
  },
};
