export default {
  en: {
    account_name: "Account Name",
    fill_in_name: "Please enter a name",
    change_password: "Change Password",
    new_password: "New Password",
    password_label: "Password",
    password_placeholder: "Enter your password",
    password_suggestion: "Use at least 8 characters",
    password_lowercase: "At least one lowercase letter",
    password_uppercase: "At least one uppercase letter",
    password_number: "At least one number",
    password_required: "Password is required.",
    password_min_length: "Password must be at least 8 characters long.",
    password_strong: "Password must be strong.",
    confirm_password_label: "Confirm Password",
    password_mismatch: "Passwords do not match.",
    save_password: "Save Password",
    info_saved: "Information Saved",
    personal_info_updated: "Your personal information has been updated.",
    suggestions: "Suggestions:",
    agree_terms: "I agree to the",
    terms_conditions: "Terms and Conditions",
    and: "and have read the",
    privacy_statement: "Privacy Statement",
    agree_terms_required: "You must agree to the terms.",
    register_button: "Create Account",
    confirm_email: "Confirm your email address",
    check_email: "Check your email & use the link to activate your account",
    signin_with_google: "Sign in with Google",
    welcome_back: "Welcome Back",
    no_account: "Don't have an account?",
    create_account: "Create one today!",
    forgot_password: "Forgot your password?",
    login_button: "Log In",
    reset_password: "Reset Password",
    register_title: "Register",
    register_message: "Try Tickable for free!",
    name_label: "Your Name",
    name_required: "Name is required.",
    name_min_length: "Name must be at least 3 characters long.",
    email_label: "Email",
    email_required: "Email is required.",
    email_invalid: "Invalid email format.",
    email_placeholder: "Enter your email",
    logout: "Logout",
    sent: "Sent",
    visitor_information: "Visitor Information",
    documents: "Documents",
    view_dashboard: "View Dashboard",
  },
  nl: {
    account_name: "Account naam",
    fill_in_name: "Vul een naam in",
    change_password: "Wachtwoord wijzigen",
    new_password: "Nieuw wachtwoord",
    password_label: "Wachtwoord",
    password_placeholder: "Voer je wachtwoord in",
    password_suggestion: "Gebruik minimaal 8 tekens",
    password_lowercase: "Minimaal één kleine letter",
    password_uppercase: "Minimaal één hoofdletter",
    password_number: "Minimaal één cijfer",
    password_required: "Wachtwoord is verplicht.",
    password_min_length: "Wachtwoord moet minimaal 8 tekens bevatten.",
    password_strong: "Wachtwoord moet sterk zijn.",
    confirm_password_label: "Herhaal Wachtwoord",
    password_mismatch: "Wachtwoord is niet gelijk.",
    save_password: "Wachtwoord Opslaan",
    info_saved: "Informatie Opgeslagen",
    personal_info_updated: "Je persoonlijke informatie is bijgewerkt.",
    suggestions: "Suggesties:",
    agree_terms: "Ik ga akkoord met de",
    terms_conditions: "Algemene Voorwaarden",
    and: "en heb kennis genomen van het",
    privacy_statement: "Privacy Statement",
    agree_terms_required: "Je moet akkoord gaan met de voorwaarden.",
    register_button: "Account maken",
    confirm_email: "Bevestig je e-mail adres",
    check_email: "Check je e-mail & gebruik de link om jouw account te activeren",
    signin_with_google: "Inloggen met Google",
    welcome_back: "Welkom Terug",
    no_account: "Nog geen account?",
    create_account: "Maak hem vandaag!",
    forgot_password: "Wachtwoord vergeten?",
    login_button: "Inloggen",
    reset_password: "Wachtwoord resetten",
    register_title: "Registreren",
    register_message: "Probeer Tickable gratis uit!",
    name_label: "Jouw Naam",
    name_required: "Naam is verplicht.",
    name_min_length: "Naam moet minimaal 3 tekens bevatten.",
    email_label: "E-mail",
    email_required: "E-mail is verplicht.",
    email_invalid: "Ongeldige e-mailindeling.",
    email_placeholder: "Voer je e-mail in",
    logout: "Uitloggen",
  },
};
