export default {
  en: {
    event: "Event",
    events: "Events",
    create_event: "Create event",
    new_event: "New event",
    event_name: "Event name",
    event_url: "Event URL",
    url_for_this_event: "URL for this event",
    this_url_is_available: "This URL is available",
    this_url_is_already_in_use: "This URL is already in use",
    event_location: "Event location",
    does_your_activity_have_multiple_time_slots: "Does your activity have multiple time slots?",
    capacity: "Capacity",
    time_slots_can_be_easily_created_later: "Time slots can be easily created later",
    more_settings: "More settings",
    event_description: "Event description",
    your_website_for_information: "Your website for information",
    basic_event_info: "Basic Event Information",
    add_data: "Add data so visitors can see the most important information",
    add_ticket_types: "Add Ticket Types",
    add_different_ticket_types: "Add different types of tickets for your visitors",
    set_theme: "Set Theme",
    set_your_theme: "Set your own theme for your ticket shop",
    sell_tickets_business_account: "Sell your tickets with a business account?",
    select_business_structure: "Select the business structure that fits best",
    corporation_with_legal_personality: "Corporation with legal personality",
    public_or_private_company: "Public or private company",
    partnership_without_legal_personality: "Partnership without legal personality",
    partnership_under_firm: "Partnership under firm",
    private_enterprise: "Private enterprise",
    sole_proprietorship: "Sole proprietorship",
    non_profit_with_legal_personality: "Non-profit with legal personality",
    foundation_or_association: "Foundation or association",
    your_ticketshop_is_ready: "Your ticket shop is ready",
    ticketshop_created: "We have created your ticket shop. Start selling tickets now or set it up for later.",
    one_time_slot: "One time slot",
    multiple_time_slots: "Multiple time slots",
    upcoming_events: "Upcoming Events",
    past_events: "Past Events",
    overview: "Overview",
    visit_ticketshop: "Visit Ticketshop",
    orders_last_30_days: "Orders Last 30 Days",
    visitors_last_30_days: "Visitors Last 30 Days",
    visitor_types_last_30_days: "Visitor Types Last 30 Days",
    checked_in: "Checked In",
    not_checked_in: "Not Checked In",
    canceled: "Canceled",
    select_event: "Select Event",
    select_visit_date: "Select Visit Date",
    activity: "Activity",
    give_your_activity_a_start_time: "Give your activity a start time",
    expand_group_by_default: "Expand group by default",
    minimum_tickets: "Minimum number of tickets",
    confirm_delete_event: "Are you sure you want to delete this event?",
    delete_event: "Delete event",
    orders_exist: "There are still orders for this event, delete them first",
    timeslot: "Timeslot",
    timeslot_management: "Indicate when your event starts and how many tickets are available in total",
    add_timeslots: "Add Timeslots",
    add_timeslot: "Add Timeslot",
    new_timeslots: "New Timeslots",
    new_timeslot: "New Timeslot",
    edit_timeslot: "Edit Timeslot",
    timeslots: "Timeslots",
    repeat_timeslot: "Repeat timeslot",
    update_similar_timeslots: "Update similar timeslots?",
    no_times_changed: "No times will be changed",
    confirm_delete_timeslot: "Are you sure you want to delete this timeslot?",
    delete_not_possible: "Deletion not possible",
    orders_or_tickets_exist: "There are probably orders or tickets on this timeslot",
    add_timeslots_question: "Are you sure you want to add {count} timeslots?",
    confirm_adding_x_timeslots: "Confirm adding {count} timeslots",
    sent: "Sent",
    visitor_information: "Visitor Information",
    documents: "Documents",
    view_dashboard: "View Dashboard",
  },
  nl: {
    event: "Evenement",
    events: "Evenementen",
    create_event: "Evenement Maken",
    new_event: "Nieuw evenement",
    event_name: "Evenement naam",
    event_url: "Evenement URL",
    url_for_this_event: "URL voor dit evenement",
    this_url_is_available: "Deze URL is beschikbaar",
    this_url_is_already_in_use: "Deze URL is al in gebruik",
    event_location: "Evenement locatie",
    does_your_activity_have_multiple_time_slots: "Heeft jouw activiteit meerdere tijdsloten?",
    capacity: "Capaciteit",
    time_slots_can_be_easily_created_later: "Tijdsloten kunnen later makkelijk in de agenda gemaakt worden",
    more_settings: "Meer instellingen",
    event_description: "Evenement beschrijving",
    your_website_for_information: "Jouw website voor informatie",
    basic_event_info: "Basis evenement informatie",
    add_data: "Voeg gegevens toe zodat bezoekers de belangrijkste informatie kunnen zien",
    add_ticket_types: "Ticketsoorten toevoegen",
    add_different_ticket_types: "Voeg verschillende typen tickets toe voor jouw bezoekers",
    set_theme: "Thema instellen",
    set_your_theme: "Stel je eigen thema in voor jouw ticketshop",
    sell_tickets_business_account: "Verkoop je tickets met een zakelijke rekening?",
    select_business_structure: "Selecteer de bedrijfsstructuur die het beste past",
    corporation_with_legal_personality: "Vennootschap met rechtspersoonlijkheid",
    public_or_private_company: "Naamloze of besloten vennootschap",
    partnership_without_legal_personality: "Vennootschap zonder rechtspersoonlijkheid",
    partnership_under_firm: "Vennootschap onder firma",
    private_enterprise: "Particuliere onderneming",
    sole_proprietorship: "Eenmanszaak",
    non_profit_with_legal_personality: "Non-profit met rechtspersoonlijkheid",
    foundation_or_association: "Stichting of vereniging",
    your_ticketshop_is_ready: "Jouw ticketshop is gereed",
    ticketshop_created: "We hebben jouw ticketshop aangemaakt. Start nu met de ticketverkoop of stel deze in voor later.",
    one_time_slot: "Eén tijdslot",
    multiple_time_slots: "Meerdere tijdsloten",
    upcoming_events: "Aankomende Activiteiten",
    past_events: "Afgelopen Activiteiten",
    overview: "Overzicht",
    visit_ticketshop: "Ticketshop bezoeken",
    orders_last_30_days: "Bestellingen Afgelopen 30 Dagen",
    visitors_last_30_days: "Bezoekers Afgelopen 30 Dagen",
    visitor_types_last_30_days: "Type Bezoekers Afgelopen 30 Dagen",
    checked_in: "Ingecheckt",
    not_checked_in: "Niet Ingecheckt",
    canceled: "Geannuleerd",
    select_event: "Evenement",
    select_visit_date: "Bezoekdatum",
    activity: "Activiteit",
    give_your_activity_a_start_time: "Geef jouw activiteit een start tijd",
    expand_group_by_default: "Groep standaard uitklappen",
    minimum_tickets: "Minimaal aantal tickets",
    confirm_delete_event: "Weet je zeker dat je dit evenement wilt verwijderen?",
    delete_event: "Evenement verwijderen",
    orders_exist: "Er zijn nog bestellingen voor dit evenement, verwijder deze eerst",
    timeslot: "Tijdslot",
    timeslot_management: "Geef aan waneer jouw evenement begint en hoeveel tickets er totaal beschikbaar zijn",
    add_timeslots: "Tijdsloten toevoegen",
    add_timeslot: "Tijdslot toevoegen",
    new_timeslots: "Nieuwe Tijdslots",
    new_timeslot: "Nieuwe Tijdslot",
    edit_timeslot: "Tijdslot wijzigen",
    timeslots: "Tijdsloten",
    repeat_timeslot: "Tijdslot herhalen",
    update_similar_timeslots: "Vergelijkbare tijdsloten bijwerken?",
    no_times_changed: "Er zullen geen tijden worden gewijzigd",
    confirm_delete_timeslot: "Weet je zeker dat je dit tijdslot wilt verwijderen?",
    delete_not_possible: "Verwijderen niet mogelijk",
    orders_or_tickets_exist: "Er zijn waarschijnlijk bestellingen of tickets op dit tijdslot",
    add_timeslots_question: "Weet je zeker dat je {count} tijdsloten wilt toevoegen?",
    confirm_adding_x_timeslots: "Bevestig het toevoegen van {count} tijdsloten",
  },
};
